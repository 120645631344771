import styled from "styled-components";
import {Breakpoints} from "./breakpoints";

interface CardWrapCenteredProps {
    alignItems?: 'center' | 'flex-start';
    childMaxWidth?: string;
}

export const CardWrapCentered = styled.div<CardWrapCenteredProps>`
    padding: 0 10px;

    & > div {
        width: 100%;
        max-width: ${props => props.childMaxWidth || '600px'};
        margin: 10px;
    }
    
    @media (min-width: ${Breakpoints.lg}) {
        display: flex;
        justify-content: center;
        align-items: ${props => props.alignItems || 'center'};
        height: calc(100vh - 64px);
    }
`;