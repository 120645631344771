import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Toolbar,
    ToolbarButton
} from "@fluentui/react-components";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
    BuildingTownhouseFilled,
    FoodAppleFilled,
    LockClosedRegular,
    MoreHorizontal24Filled
} from "@fluentui/react-icons";

export const Navigation = () => {
    const navigate = useNavigate();
    //get current url
    const location = useLocation();

    return (
        <Toolbar>
            <ToolbarButton icon={<FoodAppleFilled />} appearance={location.pathname.startsWith('/canteen') ? 'primary' : 'subtle' }>
                <NavLink to={"/canteen"}>Jídelna</NavLink>
            </ToolbarButton>
            <ToolbarButton icon={<BuildingTownhouseFilled />} appearance={location.pathname.startsWith('/accommodation') ? 'primary' : 'subtle' }>
                <NavLink to={"/accommodation"}>Ubytování</NavLink>
            </ToolbarButton>
            <div style={{flex: 1}}/>
            <Menu>
                <MenuTrigger>
                    <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem icon={<LockClosedRegular />} onClick={() => {
                            localStorage.removeItem("token");
                            navigate("/login");
                            window.location.reload(); //since isAuthenticated() does not refresh
                        }}>
                            Odhlásit se
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </Toolbar>
    )
}