import React from "react";
import {
    Skeleton,
} from "@fluentui/react-components";
import {useNavigate, useParams} from "react-router-dom";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";
import {CanteenCustomerOrders} from "../../../components/organisms/CanteenCustomerOrders";
import {CanteenCustomerForm} from "../../../components/organisms/CanteenCustomerForm";

export const CanteenCustomerEdit: React.FC = () => {
    const navigate = useNavigate();

    // get customerId from route
    const {customerId} = useParams();

    if (!customerId) {
        navigate('/canteen');
        return <Skeleton>...</Skeleton>;
    }

    return (
        <CardWrapCentered alignItems={'flex-start'} childMaxWidth={'730px'}>
            <CanteenCustomerForm customerId={customerId}/>
            <CanteenCustomerOrders customerId={customerId}/>
        </CardWrapCentered>
    )
}