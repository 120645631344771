// hook useApartmentsList is used to fetch apartments list from the server

import {useEffect, useState} from "react";
import {apiGet} from "../api/client";

export interface ApartmentResponse {
    id: number;
    name: string;
}

export const useApartmentsList = () => {
    const [apartments, setApartments] = useState<ApartmentResponse[] | undefined>(undefined);

    useEffect(() => {
        apiGet('apartments/list.php').then((response) => {
            setApartments(response);
        });
    }, []);

    return apartments;
}