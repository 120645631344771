import {useEffect, useState} from "react";
import {apiGet} from "../api/client";

export const useEntityShow = (entity: string, id: string | undefined) => {
    const [data, setData] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        apiGet(`${entity}/show.php?id=${id}`).then((response) => {
            setData(response);
        });
    }, [entity, id]);

    return data;
}