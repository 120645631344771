import React, {useEffect, useState} from 'react';
import {apiPost} from "../../api/client";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogBody, DialogContent, DialogSurface, Spinner} from "@fluentui/react-components";
import {getFormattedDate} from "../../utils/date.utils";
import {canteenPrefix, parseBarcode} from "../../utils/barcode.utils";

export const ScannerListener = () => {
    const [barcode, setBarcode] = useState('');
    const [lastKeypressTime, setLastKeypressTime] = useState(0);
    const [creating, setCreating] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            // If Enter key is pressed, clear the barcode
            if (e.key === 'Enter') {
                if (barcode.length > 5) {
                    const valueWithoutShift = barcode.replaceAll('Shift', '');

                    const startsWithPrefix = valueWithoutShift.startsWith(canteenPrefix);
                    if (!startsWithPrefix) {
                        setBarcode('');
                        return;
                    }

                    const customerId = parseBarcode(valueWithoutShift.split(":")[0]);

                    setCreating(true);

                    apiPost('canteen_orders/create.php', {
                        customer_id: customerId,
                        order_date: getFormattedDate(new Date())
                    }).finally(() => {
                        setTimeout(function() {
                            setCreating(false);
                            navigate(`/canteen/${customerId}?scanned=true`);
                        }, 500);
                    });

                    setBarcode('');
                }
            } else {
                // If the time between this keypress and the previous one is less than a certain threshold
                // (e.g., 50 milliseconds), assume it's from the barcode scanner
                const currentTime = new Date().getTime();
                if (currentTime - lastKeypressTime < 50) {
                    // Append the key to the barcode
                    setBarcode((prevBarcode) => prevBarcode + e.key);
                }
                setLastKeypressTime(currentTime);
            }
        };

        // Add event listener on mount
        window.addEventListener('keydown', handleKeyDown);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate, barcode, lastKeypressTime]); // Re-run effect only when barcode or lastKeypressTime state changes

    return (
        <>
            {creating && <Dialog open={true}>
                <DialogSurface>
                    <DialogBody>
                        <DialogContent>
                            <Spinner label={'Vytvářím záznam...'}/>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>}
        </>
    );
};