import {TableCell} from "@fluentui/react-components";
import styled from "styled-components";

export const FormattedTableCell = styled(TableCell)`
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    
    &:hover {
        overflow: visible;
        
        * {
            background-color: var(--colorSubtleBackgroundHover);
            position: absolute;
            //center vertically
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
`