import React, {useEffect, useState} from "react";
import {
    Card, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Tab,
    TabList, Title1, Toolbar, ToolbarButton
} from "@fluentui/react-components";
import {Link, useNavigate} from "react-router-dom";
import {apiGet} from "../../../api/client";
import {
    AddCircleFilled,
    HomeAddRegular, HomeRegular,
    MoreHorizontal24Filled
} from "@fluentui/react-icons";
import {ListResponse} from "../../../api/interfaces";
import {useApartmentsList} from "../../../hooks/useApartmentsList";
import {AccommodationReservationsTable} from "../../../components/organisms/AccommodationReservationsTable";
import {AccommodationReservationsCalendar} from "../../../components/organisms/AccommodationReservationsCalendar";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";
import {CollapsibleToolbar} from "../../../styled/CollapsibleToolbar";

export const AccommodationReservationList: React.FC = () => {
    const [reservations, setReservations] = useState<ListResponse | undefined>(undefined);
    const navigate = useNavigate();
    const [filterApartment, setFilterApartment] = useState<string | undefined>(undefined);
    const apartments = useApartmentsList();

    //read apartmentId from query param and set it to filterApartment
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const apartmentIdParam = urlParams.get('apartmentId');

        if (apartmentIdParam) {
            setFilterApartment(apartmentIdParam);
        } else {
            setFilterApartment('');
        }
    }, []);

    useEffect(() => {
        if (typeof filterApartment === 'undefined') {
            return;
        }

        apiGet('apartment_reservations/list.php?apartment_id=' + filterApartment).then((response) => {
            setReservations(response);
        });
    }, [filterApartment]);

    const handleTabNavigation = (apartmentId: string) => {
        if (apartmentId === '') {
            navigate('/accommodation');
        } else {
            navigate(`/accommodation?apartmentId=${apartmentId}`);
        }
        setFilterApartment(apartmentId);
    }

    if (typeof filterApartment == 'undefined') {
        return <Spinner size={'extra-large'}/>
    }

    return (
        <Card>
            <CollapsibleToolbar>
                <Title1>Rezervace ubytování</Title1>
                <div style={{flex: 1}}></div>
                <ToolbarButton icon={<AddCircleFilled/>}>
                    <Link to={'/accommodation/reservations/create'}>Nová rezervace</Link>
                </ToolbarButton>
            </CollapsibleToolbar>
            <div style={{display: 'flex', overflow: 'auto'}}>
                <TabList size={"large"} defaultSelectedValue={filterApartment}>
                    <Tab value={''} onClick={() => {
                        handleTabNavigation('');
                    }}>Všechny apartmány</Tab>
                    {typeof apartments == 'undefined' ?
                        <Spinner size={'extra-tiny'}/> : apartments?.map((apartment: any) => (
                            <Tab key={apartment.id} value={apartment.id + ""} onClick={() => {
                                handleTabNavigation(apartment.id);
                            }}>{apartment.name}</Tab>
                        ))}
                </TabList>
                <div style={{flex: 1}}></div>
                <Toolbar>
                    <Menu>
                        <MenuTrigger>
                            <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled/>}/>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem icon={<HomeAddRegular/>} onClick={() => {
                                    navigate('/accommodation/apartments/create');
                                }}>
                                    Nový apartmán
                                </MenuItem>
                                <MenuItem icon={<HomeRegular/>} onClick={() => {
                                    navigate('/accommodation/apartments');
                                }}>
                                    Spravovat apartmány
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </Toolbar>
            </div>
            <CardWrapCentered alignItems={'flex-start'} childMaxWidth={'100%'}>
                <Card>
                    <AccommodationReservationsTable
                        filterApartment={filterApartment}
                        reservations={reservations}
                        apartments={apartments}
                    />
                </Card>
                <Card style={{width: '700px', flexShrink: 'none'}}>
                    <AccommodationReservationsCalendar
                        filterApartment={filterApartment}
                        reservations={reservations}
                        apartments={apartments}
                    />
                </Card>
            </CardWrapCentered>
        </Card>
    )
        ;
}