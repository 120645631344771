import React, {useMemo} from "react";
import {Calendar, CalendarDayProps} from "@fluentui/react-calendar-compat";

export interface Event {
    id: number,
    title: string,
    dateFrom: Date,
    dateTo: Date
}

interface LocalizedCalendarProps {
    events: Event[];
    onDateSelected: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
}

export const LocalizedCalendar: React.FC<LocalizedCalendarProps> = (props) => {
    //fill getMarkedDays from props.events

    const markedDays = useMemo(() => {
        if (typeof props.events == 'undefined') {
            return [];
        }

        let result = [];
        for (let event of props.events) {
            let date = event.dateFrom;
            while (date <= event.dateTo) {
                result.push(date);
                date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
            }
        }
        return result;
    }, [props.events]);

    const calendarDayProps: Partial<CalendarDayProps> = {
        getMarkedDays: (_startingDate, _) => markedDays,
    };

    return <Calendar
        calendarDayProps={calendarDayProps}
        minDate={props.minDate}
        onSelectDate={props.onDateSelected && props.onDateSelected}
        strings={{
            months: [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            shortMonths: [
                "Led",
                "Úno",
                "Bře",
                "Dub",
                "Kvě",
                "Čer",
                "Čvc",
                "Srp",
                "Zář",
                "Říj",
                "Lis",
                "Pro"
            ],
            days: [
                "Neděle",
                "Pondělí",
                "Úterý",
                "Středa",
                "Čtvrtek",
                "Pátek",
                "Sobota"
            ],
            shortDays: [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ],
            goToToday: 'Ukázat dnešek',
        }}
        {...props}
    />
}