import React, {useState} from "react";
import {Button, Card, Field, Input} from "@fluentui/react-components";
import {apiPost} from "../../../api/client";
import {useNavigate} from "react-router-dom";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";

export const CanteenCustomerCreate: React.FC = () => {
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true)

        apiPost('canteen_customers/create.php', {
            name: name
        }).then((response) => {
            console.log(response);
            navigate('/canteen')
        }).catch((error) => {

        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <CardWrapCentered>
            <Card>
                <h1>Přidat zákazníka</h1>
                <form onSubmit={handleSubmit}>
                    <Field label={'Jméno'}>
                        <Input type={'text'} required value={name} onChange={(e) => setName(e.target.value)}
                               autoFocus={true}/>
                    </Field>
                    <div style={{height: '1rem'}}></div>
                    <Button type={'submit'} disabled={name?.length < 4 || loading}>
                        {loading ? 'Vytvářím...' : 'Vytvořit'}
                    </Button>
                </form>
            </Card>
        </CardWrapCentered>
    )
}