import {
    Button, Card,
    Field,
    Input,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Toolbar,
    ToolbarButton
} from "@fluentui/react-components";
import {ArrowLeftRegular, MoreHorizontal24Filled} from "@fluentui/react-icons";
import {Link} from "react-router-dom";
import {DeleteMenuItem} from "../atoms/DeleteMenuItem";
import React, {useEffect, useState} from "react";
import {apiGet, apiPost} from "../../api/client";
import {PrintableBarcode} from "../molecules/PrintableBarcode";
import {generateBarcode} from "../../utils/barcode.utils";

interface CanteenCustomerFormProps {
    customerId: string;
}

export const CanteenCustomerForm: React.FC<CanteenCustomerFormProps> = (props) => {
    const [barcode, setBarcode] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");

    useEffect(() => {
        if (!props.customerId) return;
        setBarcode(generateBarcode(props.customerId));
    }, [props.customerId]);

    useEffect(() => {
        apiGet(`canteen_customers/show.php?id=${props.customerId}`).then((response) => {
            setName(response?.name);
        }).finally(() => {
            setLoading(false);
        });
    }, [props.customerId]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true)

        apiPost('canteen_customers/edit.php', {
            id: props.customerId,
            name: name
        }).then((response) => {
            console.log(response);
        }).catch((error) => {

        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <Card style={{maxWidth: '600px'}}>
            <Toolbar>
                <ToolbarButton icon={<ArrowLeftRegular/>}>
                    <Link to={'/canteen'}>Zpět</Link>
                </ToolbarButton>
                <div style={{flex: 1}}/>
                <Menu>
                    <MenuTrigger>
                        <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled/>}/>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <DeleteMenuItem entity={'canteen_customers'} id={props.customerId} returnPath={'/canteen'}/>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </Toolbar>
            <h1>Zákazník #{props.customerId}</h1>
            <form onSubmit={handleSubmit} style={{display: 'flex', alignItems: 'flex-end'}}>
                <Field label={'Jméno'} style={{flex: 1}}>
                    <Input type={'text'} required value={name} onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/:/g, '');
                        setName(value)
                    }}
                           size={"large"}/>
                </Field>
                <Button type={'submit'} disabled={name?.length < 4} size={'large'}>
                    {loading ? 'Ukládám...' : 'Uložit'}
                </Button>
            </form>
            <h2>Lístek</h2>
            {barcode && <PrintableBarcode value={barcode}/>}
        </Card>
    )
}