import React, {useEffect, useState} from "react";
import {
    Button, Card,
    Field, Input,
    Menu, MenuList, MenuPopover, MenuTrigger,
    Skeleton, Toolbar, ToolbarButton,
} from "@fluentui/react-components";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";
import {ArrowLeftRegular, MoreHorizontal24Filled} from "@fluentui/react-icons";
import {DeleteMenuItem} from "../../../components/atoms/DeleteMenuItem";
import {useEntityShow} from "../../../hooks/useEntityShow";
import {apiPost} from "../../../api/client";

export const AccommodationApartmentEdit: React.FC = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const data = useEntityShow('apartments', id);
    const [name, setName] = useState("");
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setName(data?.name);
    }, [data?.name]);

    if (!id) {
        navigate('/accommodation/apartments');
        return <Skeleton>...</Skeleton>;
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true)

        apiPost('apartments/edit.php', {
            id: id,
            name: name
        }).then((response) => {
            console.log(response);
            navigate('/accommodation/apartments')
        }).catch((error) => {

        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <CardWrapCentered>
            <Card>
                <Toolbar>
                    <ToolbarButton icon={<ArrowLeftRegular/>}>
                        <Link to={'/accommodation/apartments'}>Zpět</Link>
                    </ToolbarButton>
                    <div style={{flex: 1}}/>
                    <Menu>
                        <MenuTrigger>
                            <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled/>}/>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <DeleteMenuItem entity={'apartments'} id={id} returnPath={'/accommodation/apartments'}/>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </Toolbar>
                <h1>Upravit apartmán {data?.name}</h1>
                <form onSubmit={handleSubmit}>
                    <Field label={'Nový název'}>
                        <Input type={'text'} required autoFocus={true} value={name} onChange={(e) => setName(e.target.value)}
                               size={"large"}/>
                    </Field>
                    <div style={{height: '1rem'}}></div>
                    <Button type={'submit'} disabled={name?.length < 4 || loading} size={'large'}>
                        {loading ? 'Ukládám...' : 'Uložit'}
                    </Button>
                </form>
            </Card>
        </CardWrapCentered>
    )
}