import axios from "axios";

export const urlBase = 'https://polehlova.pcneu.cz/api/';

export const axiosInstance = axios.create({
    baseURL: 'https://polehlova.pcneu.cz/api/',
    timeout: 1000,
    headers: {'X-Token': `${localStorage.getItem('token')}`},

});


export async function apiGet(path: string) {
    try {
        const response = await axiosInstance.get(path);

        //if Unauthorized, redirect to login and clear token
        if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }

        if (!response.data) {
            throw new Error(response.statusText);
        }

        return response.data;
    } catch (e) {
        debugger;
    }
}

export async function apiPost(path: string, data: any) {
    try {
        const response = await axiosInstance.post(path, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        //if Unauthorized, redirect to login and clear token
        if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }

        if (!response.data) {
            throw new Error(response.statusText);
        }
        return response.data;
    } catch (e) {
        console.log("catch")
    }
}

export async function apiDelete(path: string) {
    try {
        const response = await axiosInstance.delete(path);

        //if Unauthorized, redirect to login and clear token
        if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }

        if (!response.data) {
            throw new Error(response.statusText);
        }
        return response.data;
    } catch (e) {
        console.log("catch")
    }
}
