import React from 'react';
import {Route, BrowserRouter as Router, Routes, Navigate} from "react-router-dom";
import {Login} from "./routes/Login";
import {CanteenCustomerList} from "./routes/canteen/customers/CanteenCustomerList";
import {CanteenCustomerCreate} from "./routes/canteen/customers/CanteenCustomerCreate";
import {isAuthenticated} from "./utils/auth.utils";
import {Navigation} from "./components/Navigation";
import {CanteenCustomerEdit} from "./routes/canteen/customers/CanteenCustomerEdit";
import {AccommodationApartmentCreate} from "./routes/accommodation/apartments/AccommodationApartmentCreate";
import {AccommodationReservationList} from "./routes/accommodation/reservations/AccommodationReservationList";
import {AccommodationReservationCreate} from "./routes/accommodation/reservations/AccommodationReservationCreate";
import {Footer} from "./components/Footer";
import {AccommodationApartmentList} from "./routes/accommodation/apartments/AccommodationApartmentList";
import {AccommodationApartmentEdit} from "./routes/accommodation/apartments/AccommodationApartmentEdit";
import {AccommodationReservationEdit} from "./routes/accommodation/reservations/AccommodationReservationEdit";
import {ScannerListener} from "./components/atoms/ScannerListener";

function App() {
    const authenticated = isAuthenticated();

    if (!authenticated && window.location.pathname !== "/login") {
        window.location.href = "/login";
    }

    if (authenticated && window.location.pathname === "/login") {
        window.location.href = "/canteen";
    }

    return (
        <Router basename={'/'}>
            {authenticated && <header>
                <Navigation/>
            </header>}
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/" element={<Navigate to="/canteen" replace/>}/>
                <Route path="/canteen" element={<CanteenCustomerList/>}/>
                <Route path="/canteen/create" element={<CanteenCustomerCreate/>}/>
                <Route path="/canteen/:customerId" element={<CanteenCustomerEdit/>}/>
                <Route path="/accommodation" element={<AccommodationReservationList/>}/>
                <Route path="/accommodation/apartments" element={<AccommodationApartmentList/>}/>
                <Route path="/accommodation/apartments/:id" element={<AccommodationApartmentEdit/>}/>
                <Route path="/accommodation/apartments/create" element={<AccommodationApartmentCreate/>}/>
                <Route path="/accommodation/reservations/create" element={<AccommodationReservationCreate/>}/>
                <Route path="/accommodation/reservations/:id" element={<AccommodationReservationEdit/>}/>
            </Routes>
            <ScannerListener/>
            <Footer/>
        </Router>
    );
}

export default App;
