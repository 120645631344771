import React from "react";
import {FormattedTableCell} from "../../styled/FormattedTableCell";

interface DateTableCellProps {
    value: string
}

export const DateTableCell: React.FC<DateTableCellProps> = ({value}) => {
    const [formattedValue, setFormattedValue] = React.useState<string>('');

    React.useEffect(() => {
        const date = new Date(value);
        setFormattedValue(date.toLocaleDateString('cs-CZ'));
    }, [value]);

    return (
        <FormattedTableCell>
            {formattedValue}
        </FormattedTableCell>
    )
}