import React, {useCallback, useEffect, useState} from "react";
import {
    Card, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Text,
    ToolbarButton
} from "@fluentui/react-components";
import {apiGet, apiPost} from "../../api/client";
import {ListResponse} from "../../api/interfaces";
import {DeleteMenuItem} from "../atoms/DeleteMenuItem";
import {MoreHorizontal24Filled} from "@fluentui/react-icons";
import {LocalizedCalendar} from "../atoms/LocalizedCalendar";
import {getFormattedDate} from "../../utils/date.utils";
import {DateTableCell} from "../atoms/DateTableCell";
import {useLocation} from "react-router-dom";

export interface CanteenCustomerOrdersProps {
    customerId: string;
}

export const CanteenCustomerOrders: React.FC<CanteenCustomerOrdersProps> = (props) => {
    const [orders, setOrders] = useState<any[] | undefined>(undefined);
    const location = useLocation();

    const loadData = useCallback(() => {
        apiGet(`canteen_orders/list.php?customer_id=${props.customerId}`).then((response: ListResponse) => {
            setOrders(response?.items);
        });
    }, [props.customerId]);

    //listen to ?scanned=true in URL and reload data
    useEffect(() => {
        if (location.search.includes('scanned=true')) {
            loadData();

            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [loadData, location]);

    const handleCreate = (date: Date) => {
        //setCreating(true);
        apiPost('canteen_orders/create.php', {
            customer_id: props.customerId,
            order_date: getFormattedDate(date)
        }).then((response) => {
        }).finally(() => {
            //setCreating(false);
            loadData();
        });
    }

    useEffect(() => {
        loadData();
    }, [loadData, props.customerId]);

    return (
        <>
            <Card>
                <div style={{display: 'flex'}}>
                    <h2>Výdeje</h2>
                    {/*                <div style={{flex: '1'}}></div>
                <CollapsibleToolbar>
                    <ToolbarButton icon={<AddCircleFilled/>} onClick={() => handleCreate(new Date())}
                                   disabled={creating}>
                        Zaznamenat dnešní výdej
                    </ToolbarButton>
                </CollapsibleToolbar>*/}
                </div>
                <div style={{maxHeight: '70vh', overflow: 'auto', display: 'flex', alignItems: 'flex-start'}}>
                    {typeof orders === 'undefined' ? <Skeleton>...</Skeleton> : (
                        <>
                            {orders.length === 0 ? (
                                <p>Žádné výdeje</p>) : (
                                <>
                                    <Table style={{minWidth: '200px', width: '250px'}}>
                                        <TableHeader>
                                            <TableHeaderCell>
                                                Datum
                                            </TableHeaderCell>
                                            <TableHeaderCell>

                                            </TableHeaderCell>
                                        </TableHeader>
                                        <TableBody>
                                            {orders?.map((order: any) => (
                                                <TableRow key={order.id}>
                                                    <DateTableCell value={order.order_date}/>
                                                    <TableCell
                                                        style={{float: 'right', display: 'flex', alignItems: 'center'}}>
                                                        <Menu>
                                                            <MenuTrigger>
                                                                <ToolbarButton aria-label="More"
                                                                               icon={<MoreHorizontal24Filled/>}/>
                                                            </MenuTrigger>
                                                            <MenuPopover>
                                                                <MenuList>
                                                                    <MenuItem>
                                                                        <DeleteMenuItem entity={'canteen_orders'}
                                                                                        id={order.id}
                                                                                        onDeleted={() => {
                                                                                            loadData();
                                                                                        }}/>
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </MenuPopover>
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <div style={{position: 'sticky', top: 0, textAlign: 'center'}}>
                                        <LocalizedCalendar
                                            events={orders?.map((item) => {
                                                return {
                                                    id: item.id,
                                                    title: '',
                                                    dateFrom: new Date(item.order_date),
                                                    dateTo: new Date(item.order_date)
                                                }
                                            })}
                                            maxDate={new Date()}
                                            onDateSelected={(date) => {
                                                handleCreate(date);
                                            }}/>

                                        <Text size={200} style={{opacity: 0.5}}>Kliknutím na den se vytvoří záznam o výdeji</Text>
                                    </div>
                                </>

                            )}
                        </>

                    )}
                </div>
            </Card>
        </>

    )
}