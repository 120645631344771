import React from "react";
import {FormattedTableCell} from "../../styled/FormattedTableCell";

interface EmailTableCellProps {
    value: string
}

export const EmailTableCell: React.FC<EmailTableCellProps> = ({value}) => {
    value = value.trim();

    return (
        <FormattedTableCell
            key={"email"}
        >
            <a href={`mailto:${value}`}
               onClick={(e) => {
                   //prevent the parent onClick
                   e.stopPropagation();

                   //open mailto link
                   window.location.href = `mailto:${value}`;
               }}>{value}</a>
        </FormattedTableCell>
    )
}
