import React from "react";
import {
    Card, Spinner,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow, Toolbar, ToolbarButton
} from "@fluentui/react-components";
import {Link, useNavigate} from "react-router-dom";
import {ArrowLeftRegular, HomeAddRegular} from "@fluentui/react-icons";
import {useApartmentsList} from "../../../hooks/useApartmentsList";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";

const columns = [
    {columnKey: "name", label: "Název"},
]

export const AccommodationApartmentList: React.FC = () => {
    const apartments = useApartmentsList();
    const navigate = useNavigate();

    return (
        <CardWrapCentered>
            <Card>
                <Toolbar>
                    <ToolbarButton icon={<HomeAddRegular/>}>
                        <Link to={'/accommodation/apartments/create'}>Přidat apartmán</Link>
                    </ToolbarButton>
                    <div style={{flex: 1}}></div>
                    <ToolbarButton icon={<ArrowLeftRegular/>}>
                        <Link to={'/accommodation'}>Zobrazit rezervace</Link>
                    </ToolbarButton>
                </Toolbar>

                {typeof apartments == 'undefined' ? <Spinner label={'Načítám'}/> :
                    (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {apartments?.map((apartment: any) => (
                                    <TableRow key={apartment.id} onClick={() => {
                                        navigate(`/accommodation/apartments/${apartment.id}`);
                                    }}>
                                        <TableCell>{apartment.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )
                }
            </Card>
        </CardWrapCentered>

    );
}