import React, {useEffect, useState} from "react";
import {Button, Card, Field, Input, Select} from "@fluentui/react-components";
import {apiPost} from "../../../api/client";
import {useNavigate} from "react-router-dom";
import {CardWrapCentered} from "../../../styled/CardWrapCentered";
import {useApartmentsList} from "../../../hooks/useApartmentsList";

export const AccommodationReservationCreate: React.FC = () => {
    const apartments = useApartmentsList();

    const [name, setName] = useState("");
    const [apartmentId, setApartmentId] = useState(0);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [note, setNote] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dateFromParam = urlParams.get('dateFrom');
        const apartmentIdParam = urlParams.get('apartmentId');

        if (dateFromParam) {
            setDateFrom(dateFromParam);
            urlParams.delete('dateFrom');
        }

        if (apartmentIdParam) {
            setApartmentId(parseInt(apartmentIdParam));
            urlParams.delete('apartmentId');
        }

        if (dateFromParam || apartmentIdParam) {
            window.history.replaceState({}, '', `${window.location.pathname}`);
        }
    }, []);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (apartments && apartments.length > 0 && apartments[0] && apartmentId === 0) {
            setApartmentId(apartments[0].id);
        }
    }, [apartments, apartmentId]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true)

        apiPost('apartment_reservations/create.php', {
            name: name,
            apartment_id: apartmentId,
            email: email,
            phone: phone,
            date_from: dateFrom,
            date_to: dateTo,
            note: note
        }).then((response) => {
            console.log(response);
            navigate('/accommodation')
        }).catch((error) => {

        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <CardWrapCentered>
            <Card>
                <h1>Nová rezervace</h1>
                <form onSubmit={handleSubmit}>
                    <Field label={'Od'}>
                        <Input type={'date'} required value={dateFrom} onChange={(e) => setDateFrom(e.target.value)}/>
                    </Field>
                    <Field label={'Do'}>
                        <Input type={'date'} required value={dateTo} onChange={(e) => setDateTo(e.target.value)}/>
                    </Field>
                    <Field label={'Jméno'}>
                        <Input type={'text'} required value={name} onChange={(e) => setName(e.target.value)}
                               autoFocus={true}/>
                    </Field>
                    <Field label={'Apartmán'}>
                        <Select required value={apartmentId} onChange={(e) => setApartmentId(parseInt(e.target.value))}>
                            {apartments?.map((apartment: any) => (
                                <option key={apartment.id} value={apartment.id}>{apartment.name}</option>
                            ))}
                        </Select>
                    </Field>
                    <Field label={'Email'}>
                        <Input type={'email'} value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </Field>
                    <Field label={'Telefon'}>
                        <Input type={'text'} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </Field>
                    <Field label={'Poznámka'}>
                        <Input type={'text'} value={note} onChange={(e) => setNote(e.target.value)}/>
                    </Field>

                    <div style={{height: '1rem'}}></div>
                    <Button type={'submit'}
                            disabled={name?.length < 4 || dateFrom?.length < 4 || dateTo?.length < 4 || dateFrom > dateTo || loading}
                            size={"large"}>
                        {loading ? 'Vytvářím...' : 'Vytvořit'}
                    </Button>
                </form>
            </Card>
        </CardWrapCentered>
    )
}