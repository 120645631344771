import {
    Skeleton,
    Spinner,
    Table,
    TableBody,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "@fluentui/react-components";
import {DateTableCell} from "../atoms/DateTableCell";
import {EmailTableCell} from "../atoms/EmailTableCell";
import React from "react";
import {ApartmentResponse} from "../../hooks/useApartmentsList";
import {ListResponse} from "../../api/interfaces";
import {useNavigate} from "react-router-dom";
import {FormattedTableCell} from "../../styled/FormattedTableCell";

const columns = [
    {columnKey: "apartment_id", label: "Apartmán"},
    {columnKey: "name", label: "Jméno"},
    {columnKey: "email", label: "Email", type: "email"},
    {columnKey: "phone", label: "Telefon"},
    {columnKey: "date_from", label: "Od", type: "date"},
    {columnKey: "date_to", label: "Do", type: "date"},
    {columnKey: "note", label: "Poznámka"},
]

interface AccommodationReservationsTableProps {
    filterApartment: string;
    reservations: ListResponse | undefined;
    apartments: ApartmentResponse[] | undefined;
}

export const AccommodationReservationsTable: React.FC<AccommodationReservationsTableProps> = ({
                                                                                                  filterApartment,
                                                                                                  reservations,
                                                                                                  apartments
                                                                                              }) => {
    const navigate = useNavigate();

    const apartmentNameLookup = React.useMemo(() => {
        const lookup = new Map();
        apartments?.forEach(apartment => {
            lookup.set(apartment.id, apartment.name);
        });
        return lookup;
    }, [apartments]);

    const getApartmentName = (apartmentId: number) => {
        return apartmentNameLookup.get(apartmentId) || <Skeleton/>;
    }

    return (
        <>
            {typeof reservations == 'undefined' ? <Spinner label={'Načítám rezervace'}/> :
                <Table style={{minWidth: '600px', overflow: 'auto'}}>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => {
                                if (column.columnKey === 'apartment_id' && filterApartment !== '') {
                                    return null;
                                }

                                return (
                                    <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
                                )
                            })}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {reservations?.items?.map((reservation: any) => {

                            return (
                                <TableRow key={reservation.id} onClick={() => {
                                    navigate(`/accommodation/reservations/${reservation.id}`);
                                }}>
                                    {columns.map((column) => {
                                        if (column.columnKey === 'apartment_id') {

                                            if (filterApartment === '') {
                                                return <FormattedTableCell
                                                    key={column.columnKey}>{getApartmentName(reservation[column.columnKey])}</FormattedTableCell>
                                            }
                                            return null;
                                        }

                                        if (column.type === 'date') {
                                            return <DateTableCell value={reservation[column.columnKey]}/>;
                                        }

                                        if (column.type === 'email') {
                                            return <EmailTableCell value={reservation[column.columnKey]}/>;
                                        }

                                        return (
                                            <FormattedTableCell
                                                key={column.columnKey}>{reservation[column.columnKey]}</FormattedTableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            }
        </>

    )
}