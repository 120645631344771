import React, {useState} from "react";
import {Button, Card, Field, Input, MessageBar} from "@fluentui/react-components";
import {login} from "../api/user";
import styled from "styled-components";

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* fluent ui gradient as background, green colorish */
    background: linear-gradient(135deg, #0078d4 0%, #00b294 100%);
`;

export const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleLogin = (e: any) => {
        e.preventDefault();
        setLoading(true);
        login(email, password).then((response) => {
            console.log(response);
            window.location.href = "/canteen";
        }).catch((error) => {
            console.error(error);
            setError("Nepodařilo se přihlásit");
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Wrap>
            <Card style={{width: '100%', maxWidth: '600px'}}>
                <h1>Přihlášení</h1>
                <form onSubmit={handleLogin}>
                    <Field label={"E-mail"}>
                        <Input type={"email"} value={email} onChange={(e) => setEmail(e.target.value)}
                               autoFocus={true} size={"large"}/>
                    </Field>
                    <Field label={"Heslo"}>
                        <Input type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} size={"large"}/>
                    </Field>
                    {error && <MessageBar intent={'error'}>{error}</MessageBar>}
                    <div style={{height: '1.5rem'}}/>
                    <Button appearance={"primary"} type={"submit"} size={"large"}
                            disabled={email?.length < 1 || password.length < 1 || loading}>
                        {loading ? <>Přihlašuji se...</> : <>Přihlásit se</>}
                    </Button>
                </form>
            </Card>
        </Wrap>
    );
}