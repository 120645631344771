export const canteenPrefix = '143';

export function generateBarcode(customerId: string) {
    if(!customerId) return;
    customerId = customerId.toString();
    // get barcode ID: starts with 143, ends with customerId, and total length is 10, e.g., 1430000001
    return `${canteenPrefix}${customerId.padStart(7, '0')}`;
}

export function parseBarcode(barcode: string) {
    // remove 143 prefix and return the rest of the string, without leading zeros
    return barcode.replace(canteenPrefix, '').replace(/^0+/, '');
}