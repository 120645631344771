import {Spinner} from "@fluentui/react-components";
import React, {useMemo} from "react";
import {ApartmentResponse} from "../../hooks/useApartmentsList";
import {ListResponse} from "../../api/interfaces";
import {useNavigate} from "react-router-dom";
import {Event, LocalizedCalendar} from "../atoms/LocalizedCalendar";
import {getFormattedDate} from "../../utils/date.utils";

interface AccommodationReservationsCalendarProps {
    filterApartment: string;
    reservations: ListResponse | undefined;
    apartments: ApartmentResponse[] | undefined;
}

export const AccommodationReservationsCalendar: React.FC<AccommodationReservationsCalendarProps> = ({
                                                                                                        filterApartment,
                                                                                                        reservations,
                                                                                                        apartments
                                                                                                    }) => {
    const navigate = useNavigate();

    const events: Event[] = useMemo(() => {
        if (typeof reservations == 'undefined') {
            return [];
        }

        return reservations?.items.map((reservation) => {
            let dateFrom = new Date(reservation.date_from);
            dateFrom.setHours(0, 0, 0, 0);

            let dateTo = new Date(reservation.date_to);
            dateTo.setHours(0, 0, 0, 0);

            return {
                id: reservation.id,
                title: reservation.name,
                dateFrom: dateFrom,
                dateTo: dateTo,
            }
        });
    }, [reservations]);

    return (
        <>
            {typeof reservations == 'undefined' ? <Spinner label={'Načítám rezervace'}/> :
                <LocalizedCalendar
                    minDate={new Date()}
                    events={events}
                    onDateSelected={(date) => {
                        navigate('/accommodation/reservations/create?dateFrom=' + getFormattedDate(date) + '&apartmentId=' + filterApartment || '')
                    }}
                />
            }
        </>

    )
}