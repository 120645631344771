import React, {useEffect, useState} from "react";
import {
    Card, Spinner,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow, Title1, ToolbarButton
} from "@fluentui/react-components";
import {Link, useNavigate} from "react-router-dom";
import {apiGet} from "../../../api/client";
import {AddCircleFilled} from "@fluentui/react-icons";
import {CollapsibleToolbar} from "../../../styled/CollapsibleToolbar";
import {generateBarcode} from "../../../utils/barcode.utils";

const columns = [
    {columnKey: "id", label: "Lístek"},
    {columnKey: "name", label: "Jméno"},
]

export const CanteenCustomerList: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        apiGet('canteen_customers/list.php').then((response) => {
            setCustomers(response);
        }).finally(() => setLoading(false));
    }, []);


    return (
        <Card>
            <CollapsibleToolbar>
                <Title1>Zákazníci jídelny</Title1>
                <div style={{flex: 1}}></div>
                <ToolbarButton icon={<AddCircleFilled/>}>
                    <Link to={'/canteen/create'}>Přidat zákazníka</Link>
                </ToolbarButton>
            </CollapsibleToolbar>
            {loading ? <Spinner/> : (
                <Table>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {customers?.map((customer: any) => (
                            <TableRow key={customer.id} onClick={() => {
                                navigate(`/canteen/${customer.id}`);
                            }}>
                                <TableCell>{generateBarcode(customer.id)}</TableCell>
                                <TableCell>{customer.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </Card>
    );
}