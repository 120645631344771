import {urlBase} from "./client";
import axios from "axios";

export async function login(email: string, password: string) {
    try {
        const response = await axios.post(`${urlBase}users/login.php`, JSON.stringify({
            "email": email,
            "password": password
        }));

        if (!response.data) {
            console.error(response);
            throw new Error(response.statusText);
        }

        console.log("login", response?.data);
        localStorage.setItem('token', response?.data['access_token']);
        return response?.data;
    } catch (e) {
        console.error(e);
    }
}