import Barcode from "react-barcode";
import React, {useRef} from "react";
import {Button} from "@fluentui/react-components";
import {PrintFilled} from "@fluentui/react-icons";

interface PrintableBarcodeProps {
    value: string;
}

export const PrintableBarcode: React.FC<PrintableBarcodeProps> = ({value}) => {
    const barcodeAreaRef = useRef<HTMLDivElement>(null);

    const handlePrint = () => {
        const data = barcodeAreaRef.current?.innerHTML;
        if (data) {
            const printWindow = window.open('', 'PrintMap',
                'width=600,height=600');
            if (printWindow) {
                printWindow.document.write(data);
                printWindow.document.close();
                printWindow.print();
            }
        }
    }

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div ref={barcodeAreaRef}>
                <div>
                    <Barcode value={value}/>
                </div>
            </div>

            <div style={{marginLeft: '1rem'}}>
                <Button onClick={handlePrint} size={"large"} style={{minWidth: 'unset'}}>
                    <PrintFilled/>
                </Button>
            </div>

        </div>
    )
}