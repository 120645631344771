import React from "react";
import {MenuItem, Text} from "@fluentui/react-components";
import {DeleteFilled} from "@fluentui/react-icons";
import {apiDelete} from "../../api/client";
import {useNavigate} from "react-router-dom";

interface DeleteButtonProps {
    entity: string;
    id: string;
    returnPath?: string;
    onDeleted?: () => void;
}

export const DeleteMenuItem: React.FC<DeleteButtonProps> = ({entity, id, returnPath, onDeleted}) => {
    const navigate = useNavigate();
    const handleDelete = () => {
        console.log(`Deleting ${entity}`);

        apiDelete(`${entity}/delete.php?id=${id}`).then((response) => {
            if (returnPath) {
                navigate(returnPath);
            }

            onDeleted && onDeleted();
        });
    }
    return (
        <MenuItem icon={<DeleteFilled/>} onClick={handleDelete}>
            <Text>Smazat</Text>
        </MenuItem>
    )
}